import classnames from 'classnames';
import React, { FC, PropsWithChildren } from 'react';

export type TagStateProps =
  | 'danger'
  | 'info'
  | 'standard'
  | 'success'
  | 'warning';

const Tag: FC<
  PropsWithChildren<{
    className?: string;
    state?: TagStateProps;
  }>
> = ({ children, className, state = 'standard' }) => (
  <span
    className={classnames(
      className,
      'status whitespace-nowrap rounded px-[8px] py-[1px] text-xs',
      {
        'bg-gray-100': 'standard' === state,
        'border-danger-200 bg-danger-100 text-danger-800': 'danger' === state,
        'border-info-200 bg-info-100 text-info-800': 'info' === state,
        'border-success-200 bg-success-100 text-success-800':
          'success' === state,
        'border-warning-200 bg-warning-100 text-warning-800':
          'warning' === state,
      },
    )}
  >
    {children}
  </span>
);

export default Tag;
