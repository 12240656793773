import { DocumentType, PageType } from '@innedit/innedit-type';
import { PageData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

import { ListItemProps } from '~/containers/Espace/List/props';
import IconEye from '~/icons/Eye';
import IconEyeSlash from '~/icons/EyeSlash';
import IconPlus from '~/icons/Plus';
import IconStar from '~/icons/Star';
import { colors } from '~/styles/theme';

import ListItem from './index';

const ListItemPage: FC<ListItemProps<PageType, PageData>> = ({
  changePosition,
  className,
  docId,
  index,
  onClick,
  model,
}) => {
  const [isOpen] = useState<boolean>(true);
  const [doc, setDoc] = useState<DocumentType<PageType>>();
  const [docs, setDocs] = useState<DocumentType<PageType>[]>();

  useEffect(() => {
    let isMounted = true;
    const unsub = model.watchById(docId, document => {
      if (isMounted) {
        setDoc(document);
      }
    });

    return () => {
      isMounted = false;
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  useEffect(() => {
    let isMounted = true;
    if (isOpen && doc && doc.nbChildren && doc.nbChildren > 0) {
      const subModel = new PageData({
        channelId: model.channelId,
        espaceId: model.espaceId,
      });
      subModel
        .find({
          orderDirection: 'desc',
          orderField: 'datetime',
          wheres: {
            parent: docId,
          },
        })
        .then(newDocs => {
          if (isMounted) {
            setDocs(newDocs);
          }

          return true;
        })
        .catch(console.error);
    }

    return () => {
      isMounted = false;
    };
  }, [doc, isOpen]);

  if (!doc) {
    return null;
  }

  // const handleOnClick = (event: SyntheticEvent) => {
  //   event.preventDefault();
  //
  //   setIsOpen(io => {
  //     if (io) {
  //       setDocs(undefined);
  //     }
  //
  //     return !io;
  //   });
  // };

  return (
    <ListItem
      actions={[
        {
          icon: doc.hidden ? IconEyeSlash : IconEye,
          label: 'Changer la visibilité',
        },
        {
          icon: IconPlus,
          label: 'Ajouter une sous-page',
          to: `/espaces/${doc.espaceId}/channels/${doc.channelId}/pages/create?parent=${docId}`,
        },
      ]}
      after={
        docs &&
        docs.length > 0 && (
          <div className="bg-light-400 pl-6">
            {docs.map(d => (
              <ListItemPage
                key={`${d.id}`}
                docId={d.id}
                model={model}
                onClick={onClick}
              />
            ))}
          </div>
        )
      }
      changePosition={changePosition}
      className={className}
      displayActionId={false}
      doc={doc}
      icon={
        doc.isHomePage && (
          <IconStar className="h-[18px] w-[18px]" color={colors.neutral[300]} />
        )
      }
      index={index}
      label={doc.label}
      onClick={onClick}
    >
      {/* {doc.nbChildren > 0 ? ( */}
      {/*  <> */}
      {/*    {isOpen ? ( */}
      {/*      <IconPlusSquare */}
      {/*        color={colors.light[700]} */}
      {/*        onClick={handleOnClick} */}
      {/*      /> */}
      {/*    ) : ( */}
      {/*      <IconMinusSquare */}
      {/*        color={colors.light[700]} */}
      {/*        onClick={handleOnClick} */}
      {/*      /> */}
      {/*    )} */}
      {/*  </> */}
      {/* ) : ( */}
      {/*  <IconSquare color={colors.light[700]} /> */}
      {/* )} */}
    </ListItem>
  );
};

export default ListItemPage;
